import {
    createSlice,
    createAsyncThunk,
    isRejectedWithValue,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  export const deletedUsers = createAsyncThunk("/deletedUsers/content", async (data) => {
    try {
      if (data.searchText === undefined) {
        const response = await axios.get(
          `/get_all_users?page=${data.page}&user_type=${data.user_type || ""}&type=deleted`,
          {}
        );
        console.log('response',response.data)
        return response.data;
      } else {
        const response = await axios.get(
          `/get_all_users?search=${data.searchText}&page=${data.page}&user_type=${
            data.user_type || ""
          }&type=deleted`,
          {}
        );
        return response.data;
      }
    } catch (error) {
      return Promise.reject(
        new Error(error.response?.data?.message || "No Result Found")
      );
    }
  });
  
  
  export const deletedUserSlice = createSlice({
    name: "deletedUser",
    initialState: {
      isLoading: false,
      user: [],
      error: null,
    },
    reducers: {},
  
    extraReducers: {
      [deletedUsers.pending]: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      [deletedUsers.fulfilled]: (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      },
      [deletedUsers.rejected]: (state, action) => {
        state.isLoading = false;
        state.user = [];
        state.error = action.payload || "No User Found";
      },
    },
  });
  
  export const { deleteLead } = deletedUserSlice.actions;
  
  export default deletedUserSlice.reducer;
  