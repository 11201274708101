import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch passwords
export const getPasswords = createAsyncThunk("/passwords/get", async () => {
  const response = await axios.get(`/protected_passwords`);
  return response.data;
});

// Async thunk to create a new password
export const createPassword = createAsyncThunk(
  "/passwords/create",
  async (passwordData) => {
    console.log("passwordData", passwordData);
    const response = await axios.post(`/protected_password`, {
      ...passwordData,
    });
    return response.data;
  }
);

// Async thunk to delete a password
export const deletePassword = createAsyncThunk(
  "/passwords/delete",
  async (id) => {
    const response = await axios.delete(`/protected_password/${id}`);
    return response.data;
  }
);

export const passwordSlice = createSlice({
  name: "password",
  initialState: {
    isLoading: false,
    passwords: [],
  },
  reducers: {},
  extraReducers: {
    [getPasswords.pending]: (state) => {
      state.isLoading = true;
    },
    [getPasswords.fulfilled]: (state, action) => {
      state.passwords = action.payload;
      state.isLoading = false;
    },
    [getPasswords.rejected]: (state) => {
      state.isLoading = false;
    },

    [createPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [createPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      // Refresh the page after creating the password
      window.location.reload();
    },
    [createPassword.rejected]: (state) => {
      state.isLoading = false;
    },

    [deletePassword.pending]: (state) => {
      state.isLoading = true;
    },
    [deletePassword.fulfilled]: (state, action) => {
      state.passwords = state.passwords.filter(
        (password) => password.id !== action.payload.id
      );
      state.isLoading = false;
    },
    [deletePassword.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default passwordSlice.reducer;
