import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getReelContent = createAsyncThunk(
  "/reel/content",
  async (page) => {
    const response = await axios.get(
      `/reels?page=${page}`,
      {}
    );
    console.log('Reels',response.data)
    return response.data;
  }
);

export const deleteReelContent = createAsyncThunk(
  "deleteReel/content",
  async (id) => {
    const response = await axios.delete(`/reel/${id}`);

    return response.data;
  }
);

export const reelSlice = createSlice({
  name: "reel",
  initialState: {
    isLoading: false,
    reel: [],
  },
  reducers: {
    deleteReel: (state, action) => {
      const indexToRemove = state.reel.reels.findIndex(
        (item) => item.stream_id == action.payload
      );

      if (indexToRemove !== -1) {
        state.reel.reels.splice(indexToRemove, 1);
      }
    },
  },

  extraReducers: {
    [getReelContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getReelContent.fulfilled]: (state, action) => {
      state.reel = action.payload;
      state.isLoading = false;
    },
    [getReelContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { deleteReel } = reelSlice.actions;

export default reelSlice.reducer;
